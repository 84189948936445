.footer {
    background: #343a40;
    color: #fff;
    padding: 1rem 0;
    text-align: center;
  }
  
  .footer a {
    color: #fff;
    margin: 0 10px;
  }
  
  .footer p {
    margin: 0;
  }